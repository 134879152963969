<template>
  <div class="content">
    <MainHeader
      :description="$t('pages.school_management.program_content.description')"
      :module="$t('pages.school_management.title')"
      :page-title="$t('pages.school_management.program_content.title')"
    />
    <wrapper class="content-page">
      <div class="container-filters">
        <form class="filter-area">
          <s-select
            v-model="selectedDocumentType"
            class="s-select-document"
            :disabled="false"
            :label="$t('pages.school_management.program_content.documentType')"
            :loading="null"
            :options="documentType"
            :placeholder="
              $t('pages.school_management.program_content.selectType')
            "
            size="large"
            track-by="text"
            @select="clearDocumentSelection()"
          />
          <s-button
            icon="search"
            size="large"
            @click="selectDocument()"
          />
        </form>
      </div>
      <div class="doc-wrapper">
        <ColorDocsMiniature
          v-for="item in allDocuments"
          :key="item.id"
          card-color="#5B7A95"
          :card-date="item.publicationAt"
          :card-download="getFileFullUrl(item.fileLink)"
          :card-title="selectedDocumentType.text"
          :card-type="item.title"
        />
      </div>
    </wrapper>
    <EmptyState
      v-if="totalDocuments === 0 && !filtered"
      :image-src="require(`@/assets/img/examSearch.svg`)"
      img-empty-result-class
    >
      <h6 class="empty-desc">
        Use os filtros acima para acessar o documento da prova que você procura
      </h6>
    </EmptyState>
  </div>
</template>

<script>
import MainHeader from '@/components/shared/MainHeader.vue'
import ColorDocsMiniature from '@/components/shared/ColorDocsMiniature.vue'
import EmptyState from '@/components/shared/EmptyState.vue'

import schoolManagementService from '@/services/documentsService'

import { getFileFullUrl } from '@/utils/files'

export default {
  name: 'ProgramContent',
  components: {
    MainHeader,
    ColorDocsMiniature,
    EmptyState,
  },
  data() {
    return {
      allDocuments: [],
      selectedDocumentType: null,
      totalDocuments: 0,
      filtered: false,
      documentType: [
        {
          text: this.$t('pages.school_management.program_content.grade'),
          value: 'SERIE',
        },
        {
          text: this.$t('pages.school_management.program_content.curricular'),
          value: 'CURRICULAR',
        },
      ],
    }
  },
  methods: {
    selectDocument() {
      this.clearDocumentSelection()

      if (this.selectedDocumentType.text === this.documentType[0].text) {
        this.getAllProgramContentDocuments(this.selectedDocumentType.text)
      }
      if (this.selectedDocumentType.text === this.documentType[1].text) {
        this.getAllProgramContentDocuments(this.selectedDocumentType.text)
      }
    },
    async getAllProgramContentDocuments(selection) {
      try {
        const { data } = await schoolManagementService.getProgramContentDocs()
        this.totalDocuments = data.categories.length
        this.filtered = true

        data.categories.forEach((element) => {
          if (element.value === selection) {
            element.documents.forEach((document) => {
              this.allDocuments.push(document)
            })
          }
        })
      } catch (error) {
        this.$toasted.global.error({
          message: `Erro ao carregar dados. ${error}`,
        })
      }
    },
    clearDocumentSelection() {
      this.allDocuments = []
    },
    getFileFullUrl,
  },
}
</script>

<style lang="sass">

.sas-wrapper.content-page
  padding-bottom: 20px
  background: transparent
  padding-bottom: 20px

.content-page
  .sas-box
    border-radius: 0px !important

    +mq-l--mf
      border-radius: $border-radius-m !important

.doc-wrapper
  display: grid
  grid-gap: 32px

  +mq-m--mf
    grid-template-columns: repeat(2, 1fr)

  +mq-l--mf
    grid-template-columns: repeat(4, 1fr)

.main-subtitle
  display: inline-block
  margin-bottom: 36px
  font-size: 22px
  line-height: 28px
  color: #666E75

.simple-separator
  border: 0
  border-bottom: 1px solid #C4C4C4
  margin: 23px 0

.books-filter
  margin: 24px 0 32px 0
  display: inline

  .btn-content
    display: inline
    margin-right: 16px

.btn-book.sas-button
  margin-bottom: 14px
  box-shadow: none
  border: 1px solid #53595F

  &:not(:last-of-type)
    margin-right: 16px

  &:focus
    box-shadow: none !important

  &.active-book
    background: #666E75
    border: 1px solid #666E75 !important
    color: $color-white !important

.container-filters
  width: 100%
  display: flex
  align-items: flex-start
  flex-flow: wrap
  margin-bottom: 24px
  padding-top: 20px

  +mq-m--mf
    flex-direction: row
    grid-gap: 16px
    grid-template-columns: 35% 40% 5% 1fr
    padding-top: 20px

  +mq-l--mf
    flex-direction: row
    grid-gap: 16px
    grid-template-columns: 35% 40% 5% 1fr
    padding-top: 20px
</style>
